<template>
    <el-popover
        v-model="visible"
        v-loading="loading"
        popper-class="gb-chart-comments"
        placement="bottom"
        width="360"
        trigger="click"
        @hide="handleCancel"
    >
        <template v-if="!editable">
            <div class="gb-chart-comments-title">
                分析说明
                <i class="iconfont icon-bianji" @click="handleEditable"></i>
            </div>
            <div
                v-if="comment"
                class="gb-chart-comments-current"
                v-html="comment"
            ></div>
            <div
                v-else
                class="gb-chart-comments-current-default"
                v-html="commentView"
            ></div>
            <div class="btns">
                <el-button
                    type="primary"
                    @click="handleEditable"
                >
                    编辑
                </el-button>
                <el-button @click="handleClose">关闭</el-button>
            </div>
        </template>
        <template v-else>
            <editor v-model="comment" class="edit-area"></editor>
            <div class="btns">
                <el-button
                    type="primary"
                    :disabled="!this.comment"
                    @click="handleSave"
                >
                    确定
                </el-button>
                <el-button @click="handleCancel">取消编辑</el-button>
                <el-button @click="handleClose">关闭</el-button>
            </div>
        </template>
        <span
            slot="reference"
        ><i
            v-if="form.spuId"
            class="iconfont icon-fenxishuoming"
            @click.stop="handleVisible(true)"
        ></i></span>
    </el-popover>
</template>
<script>
import editor from 'components/Editor'
import sellSvc from 'services/sell'

export default {
    name: 'AnalysisComments',
    components: { editor },
    props: {
        form: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            visible: false,
            comment: null,
            commentClone: null,
            editable: false,
            loading: false
        }
    },
    computed: {
        commentView () {
            return this.comment || '暂无分析说明'
        }
    },
    watch: {
        form: {
            immediate: true,
            deep: true,
            async handler (newVal) {
                await this.getComments()
            }
        }
    },
    methods: {
        handleVisible (boo) {
            this.visible = boo
        },
        handleEdit (boo) {
            this.editable = boo
        },
        handleEditable () {
            if (!this.form.spuId) {
                this.$message.warning(
                    '请选择商品，如未关注商品, 请进入商品页面添加关注'
                )
                return
            }
            this.handleEdit(true)
        },
        handleClose () {
            this.comment = this.commentClone
            this.handleVisible(false)
            this.handleEdit(false)
        },
        handleCancel () {
            this.comment = this.commentClone
            this.handleEdit(false)
        },
        async handleSave () {
            this.loading = true
            const query = {}
            ;['targetType', 'startDay', 'endDay'].forEach(item => {
                query[item] = this.form[item]
            })
            query.targetId = this.form.spuId
            query.targetType = '1'
            query.comment = this.comment
            try {
                const data = await sellSvc.setComment(query)
                this.commentClone = this.comment
                // this.handleVisible(false)
                this.handleEdit(false)
            } finally {
                this.loading = false
            }
        },
        async getComments (type = '') {
            // 获取评论相关数据 改天放到comments
            const { targetType, spuId: targetId, startDay, endDay } = this.form
            if (!targetId) {
                return
            }
            const query = {
                targetType,
                targetId,
                startDay,
                endDay
            }
            query.targetType = '1'

            const { data } = await sellSvc[`get${type}Comment`](query)
            if (!type) {
                this.comment = data && data.comment ? data.comment : ''
                this.commentClone = this.comment
            } else {
                this.commentAssociateData = data || []
            }
        }
    }
}
</script>
<style lang="scss">
.gb-chart-comments {
    font-size: inherit;
}
</style>
<style lang="scss" scoped>
.el-popover__reference {
    display: flex;
}

.icon-fenxishuoming {
    font-size: 28px;
    color: #a7b6c3;
    cursor: pointer;

    &:hover {
        color: #d0d6da;
    }
}

.gb-chart-comments {
    max-height: 500px;
    overflow-y: auto;

    &-current {
        min-height: 200px;
        max-height: 348px;
        padding: 10px;
        overflow-y: auto;

        &-default {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 200px;
            color: #c0c4cc;
        }
    }

    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 54px;
        padding: 0 13px;
        font-size: 14px;
        font-weight: 500;
        line-height: 54px;
        color: #606266;
        background: #f7f9fc;

        i {
            display: inline-block;
            font-size: 12px;
            color: #909399;
            cursor: pointer;
        }
    }

    .btns {
        padding: 12px 0 0;
        text-align: right;

        .el-button {
            border-radius: 8px;
        }
    }
}
</style>
